import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Background = ({ type, children, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      layout: contentfulWebsiteLayout {
        blueBg {
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        greyBg {
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        greyBgInverse {
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)

  const { blueBg, greyBg, greyBgInverse } = data.layout

  const bg =
    type === "blue" ? blueBg : type === "greyInverse" ? greyBgInverse : greyBg
  return (
    <BackgroundImage
      style={{ backgroundPosition: "center", backgroundSize: "100% 100%" }}
      {...props}
      loading="eager"
      fadeIn={false}
      fluid={bg.fluid}
    >
      {children}
    </BackgroundImage>
  )
}

export default Background
